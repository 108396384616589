// 获取当天0点时间戳
const getZeroTime = () => {
  let time = new Date();
  let result =
    time.getTime() -
    time.getHours() * 60 * 60 * 1000 -
    time.getMinutes() * 60 * 1000 -
    time.getSeconds() * 1000 -
    time.getMilliseconds();
  return result;
};

const getRequestFormat = (date: string) => {
  if (!date) return "";
  let temp = [] as any;
  if (date.indexOf("/") !== -1) temp = date.split("/");
  else if (date.indexOf("-") !== -1) temp = date.split("-");
  return `${temp[2]}/${temp[1]}/${temp[0]}`;
};
const exportFile = (data: any, fileName: string, type: string) => {
  let blob = new Blob([data], {
    //type类型后端返回来的数据中会有，根据自己实际进行修改
    // 表格下载为 application/xlsx，压缩包为 application/zip等，
    type,
  });
  let filename = fileName;
  if (typeof (window as any).navigator.msSaveBlob !== "undefined") {
    (window as any).navigator.msSaveBlob(blob, filename);
  } else {
    var blobURL = window.URL.createObjectURL(blob);
    // 创建隐藏<a>标签进行下载
    var tempLink = document.createElement("a");
    tempLink.style.display = "none";
    tempLink.href = blobURL;
    console.log(tempLink);
    tempLink.setAttribute("download", `${filename}`);
    if (typeof tempLink.download === "undefined") {
      tempLink.setAttribute("target", "_blank");
    }
    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
    window.URL.revokeObjectURL(blobURL);
  }
};
const getDayTimes = (day: number) => {
  if (day === 0) {
    return {
      start: 0,
      end: 0,
    };
  }
  let time = new Date();
  let a_day = 86400000;
  // 今天0点的时间戳
  let today_zero =
    time.getTime() -
    time.getHours() * 60 * 60 * 1000 -
    time.getMinutes() * 60 * 1000 -
    time.getSeconds() * 1000 -
    time.getMilliseconds();
  return {
    start: today_zero - (day - 1) * a_day,
    end: today_zero + a_day - 1,
  };
};

const convertIsoToLocalDate = (datevalue: string) => {
  const converteddate = Date.parse(datevalue);
  const date_not_formatted = new Date(converteddate);
  let month = (date_not_formatted.getMonth() + 1) as any;
  if (month < 10) {
    month = `0${month}`;
  }
  let day = date_not_formatted.getDate() as any;
  if (day < 10) {
    day = `0${day}`;
  }

  return `${day}/${month}/${date_not_formatted.getFullYear()}`;
};

const formatDate = (timestampString: string): string => {
  // 将字符串转换为数字
  const timestamp = parseInt(timestampString, 10);

  // 创建日期对象，自动按照本机时区转换
  const date = new Date(timestamp);

  // 获取日期的各部分并格式化
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // 月份从0开始，需要加1
  const year = date.getFullYear();

  // 返回格式化的日期字符串
  return `${day}/${month}/${year}`;
};

const formatAmount = (amount: string): string => {
  // 使用正则表达式为整数部分添加千位分隔符
  const [integerPart, decimalPart] = amount.split("."); // 分割整数部分和小数部分
  const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ","); // 为整数部分添加千位分隔符
  return decimalPart ? `${formattedInteger}.${decimalPart}` : formattedInteger; // 合并整数和小数部分
};

export {
  getZeroTime,
  getRequestFormat,
  exportFile,
  getDayTimes,
  convertIsoToLocalDate,
  formatDate,
  formatAmount,
};
